import Swal from "sweetalert2";
import Swiper from "swiper";
import { Scrollbar, Autoplay, Navigation, Pagination, Thumbs} from 'swiper/modules';

(function ($) {
    $(document).ready(function () {
        $('.navbar_toggler').on('click', function () {
            $('.header__nav').addClass('active')
            $('body').addClass('hidden_body');
            $('.js_nav_open').show();
        })

        $('.header__nav_close').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(".js_nav_open").on('click', function () {
            $('body').removeClass('hidden_body');
            $(this).hide();
            $('.header__nav').removeClass('active');
        });

        $('.header__nav a').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $('.callback_btn').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                        <div class="swal_inner">
                            <p class="h2 text-center">Запис на прийом</p>
                            <p class="text-center mb-5">Заповніть свої дані для запису на консультацію</p>
                            <form action="" class="form" id="swal_popup1">
                                <div class="form__fildset">
                                    <p class="form__label">Введіть ваше ім’я</p>
                                    <input class="form__input" type="text" placeholder="Олександр">
                                </div>
                                <div class="form__fildset">
                                    <p class="form__label">Введіть ваш номер телефона</p>
                                    <input class="form__input" type="tel" placeholder="+380">
                                </div>
                                <button type="submit" class="button popup1_submit_js">Записатись на прийом</button>
                            </form>
                        </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })

            $('#swal_popup1').on('submit', function (e) {
                e.preventDefault();
                console.log('popup submit');
                thankPopup();
            });

        });


        function thankPopup() {
            Swal.fire({
                html: `
                        <div class="swal_inner">
                            <p class="h2 text-center">Дякуємо за ваше звернення!</p>
                            <p class="text-center mb-5">Наш менеджер зв’яжеться з вами найближчим часом.</p>
                        </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })
        }

        gsap.set('.animation_text span', {width: 0})

        gsap.timeline({
            scrollTrigger: {
                trigger: '.animation_text span',
                scrub: 0.5,
                start: "top 60%",
                end: "top 10%",
                //markers:true
            }
        })
            .to('.animation_text span', {
                width: '100%',
                duration: 1,
                ease: 'none',
                stagger: 1
            })

        function swiperServicesDisplay() {

            if ($('.services_slider').length) {
                let services_slider = new Swiper(".services_slider", {
                    modules: [Navigation, Pagination],
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                });

                var windowWidth = window.screen.width;
                if (windowWidth > 767) {
                    services_slider.destroy()
                }
            }
        }

        //first init
        swiperServicesDisplay()
        //etc

        //alternative resize
        function resizeHandler() {
            swiperServicesDisplay()
            //etc
        }

        window.addEventListener("resize", resizeThrottler, false);
        let resizeTimeout;

        function resizeThrottler() {
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(function () {
                    resizeTimeout = null;
                    resizeHandler();
                }, 250);
            }
        }


        const certificate_slider = new Swiper('.certificate_slider', {
            modules: [Autoplay],
            slidesPerView: "auto",
            spaceBetween: 10,
            autoplay: {
                delay: 0,
            },
            speed: 10000,
            loop: true,
            breakpoints: {
                1200: {
                    spaceBetween: 25,
                }
            }
        });


        const testimonials_swiper = new Swiper(".testimonials_slider", {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 5,
            navigation: {
                prevEl: ".testimonials-button-prev",
                nextEl: ".testimonials-button-next",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1700: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                }
            }
        });

        const advantages_swiper = new Swiper(".advantages_slider", {
            modules: [Pagination],
            slidesPerView: "auto",
            spaceBetween: 8,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 25,
                }
            }
        });

        const team_certificate_swiper = new Swiper(".team_certificate_slider", {
            modules: [Navigation, Pagination],
            slidesPerView: 'auto',
            spaceBetween: 10,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                1199: {
                    slidesPerView: 4,
                    spaceBetween: 25,
                }
            }
        });

        const team_nav_swiper = new Swiper(".team_slider__nav", {
            modules: [Scrollbar],
            slidesPerView: 'auto',
            spaceBetween: 10,
            freeMode: true,
            watchSlidesProgress: true,
            scrollbar: {
                el: ".swiper-scrollbar",
            },
            mousewheel: true,
            breakpoints: {
                1200: {
                    direction: 'vertical',
                }
            },
        });

        const team_main_swiper = new Swiper(".team_slider__main", {
            modules: [Thumbs],
            loop: true,
            spaceBetween: 10,
            thumbs: {
                swiper: team_nav_swiper,
            },
        });


        const gallery_swiper = new Swiper(".gallery_slider", {
            modules: [Navigation, Pagination],
            loop: true,
            slidesPerView: 1,
            simulateTouch: false,
            noSwipingClass: 'swiper-no-swiping',
            noSwiping: true,
            allowTouchMove: false,
            spaceBetween: 20,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            pagination: {
                el: '.swiper-pagination-parent',
                clickable: true,
            },
        });

        const gallery_swiper_child = new Swiper(".gallery_child_slider", {
            modules: [Pagination],
            loop: true,
            slidesPerView: 1,
            // noSwiping: false,
            pagination: {
                el: '.swiper-pagination-child',
                clickable: true,
            },
        });

        $(".accordion .accordion-toggle").on('click', function () {
            $(this).parent().parent().find(".accordion-toggle").removeClass("active");
            $(this).next().toggleClass("active");
            $(this).parent().parent().find(".accordion-content").not($(this).next()).removeClass("active");
            if ($(this).next().hasClass("active")) {
                $(this).addClass("active");
            }
        });

    });
}(jQuery));

